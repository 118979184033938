import CupomActionTypes from "./actions-types";

const initialState = {
    currentCupom: { value: 0, min_value: 0 }
}

const cupomReducer = (state = initialState, action) => {
    switch (action.type) {
        case CupomActionTypes.ADDCUPOM:
            return { currentCupom: action.payload };
        case CupomActionTypes.REMOVECUPOM:
            return { currentCupom: action.payload };
        case CupomActionTypes.CLEANCUPOM:
            return { currentCupom: action.payload }
        default:
            return state;
    }
};

export default cupomReducer;